<template>
    <CertificateTemplate :type_id="type_id" :title="title" :type="type"></CertificateTemplate>
</template>

<script>
    import CertificateTemplate from './index.vue'
export default {
    components:{CertificateTemplate},

    data : () =>({
        type_id:1,
        type:'CERTIFICATES',
        title:"Certificate Template"
    })

    
}
</script>